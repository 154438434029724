import { ConnectButton } from "@rainbow-me/rainbowkit"
import Box from "@mui/material/Box"

const Connect = () => {
  return (
    <Box id="connect-button-parent">
      <ConnectButton
        label="CONNECT"
        chainStatus="icon"
        accountStatus={{
          smallScreen: "address",
          largeScreen: "address"
        }}
        showBalance={{
          largeScreen: false,
          smallScreen: false
        }}
      />
    </Box>
  )
}

export default Connect
