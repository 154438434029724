import { utils, BigNumber } from "ethers"
import {
  Box,
  Typography
} from "@mui/material"

interface Props {
  currentPriceWei: BigNumber,
  currencySymbol: string
}

const FixedMintingPrice = ({currentPriceWei, currencySymbol}: Props) => {
  return (
    <Box sx={{marginBottom: 3}}>
      <Box>
        { 
          <Typography fontWeight="bold">Fixed Price: {utils.formatEther(currentPriceWei.toString())} {currencySymbol}</Typography>
        }
      </Box>
    </Box>
  )
}

export default FixedMintingPrice