import { CORE_CONTRACT_ADDRESS } from "config"
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Link from '@mui/material/Link'

interface Props {
  width: number
  invocation?: BigInt
  aspectRatio?: number
}

const BlankTokenView = ({
  width,
  invocation,
  aspectRatio=1
}: Props) => {
  const height = width / aspectRatio
  return (
    <Box width={String(width)+"px"} height={String(height)+"px"}>
      <Card sx={{borderRadius: 0, boxShadow: 0}}>
        {
          <Box width={width} height={height}/>
        }
      </Card>
      { invocation !== undefined && 
        (
        <Box sx={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
          <Link href={`/token/${CORE_CONTRACT_ADDRESS?.toLowerCase()}-${0}`} sx={{fontSize: "14px", textDecoration: "none"}}>
            Mint #{ invocation?.toString() }
          </Link>
        </Box>
        )
      }
    </Box>
  )
}

export default BlankTokenView