import { S3_MEDIA_URL, CCA_MEDIA_URL, CORE_CONTRACT_ADDRESS } from "config"

interface Props {
  tokenId: string
  width: number
  height: number
  isStatic?: boolean
}

const TokenImage = ({tokenId, width, height, isStatic}: Props) => {
  return (
    <img
      src={isStatic ? `https://img.concentric.art/cdn-cgi/image/width=${width},height=${height},quality=80/${tokenId}.png` : `${CCA_MEDIA_URL}/${tokenId}.png`}
      alt={tokenId}
      width={width}
      height={height}
    />
  )
}

export default TokenImage
