import moment from "moment"
import { CORE_CONTRACT_ADDRESS, GENERATOR_URL, CCA_MEDIA_URL } from "config"
import { parseAspectRatio } from "utils/scriptJSON"
import {
  Box,
  Typography,
  Grid,
  Alert,
  Button,
  Breadcrumbs,
  Stack,
  Divider
} from "@mui/material"
import { PlayArrow, Pause } from "@mui/icons-material"
import TokenTraits from "components/TokenTraits"
import Address from "components/Address"
import Loading from "components/Loading"
import TokenView from "components/TokenView"
import useToken from "hooks/useToken"
import useWindowSize from "hooks/useWindowSize"
import { useEffect } from "react"
import ReactGA from 'react-ga4';

interface Props {
  id: string,
  onRendered: any
}

interface MarketplaceConfig {
  logo: string;
  url: string;
}

let wasLoading = false;
let hasRunGA = false;

const TokenDetails = ({ id, onRendered }: Props) => {
  // const theme = useTheme()
  const windowSize = useWindowSize()
  const { loading, error, data } = useToken(id)
  const token = data?.token
  
  if (!hasRunGA) {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    hasRunGA = true;
  }

  useEffect(() => {
    if (loading) {
      wasLoading = true;
    } else if (!loading && wasLoading) {
      setTimeout(() => {
        onRendered();
      }, 500);
    }
  });

  if (loading) {
    return <Loading/>
  }

  if (error) {
    <Box>
      <Alert severity="error">
        Error loading token
      </Alert>
    </Box>
  }

  const isMobile = windowSize.width < 900;
  const innerPadding = isMobile ? 10 : 20;
  const width = isMobile ? windowSize.width - innerPadding * 2 : Math.min((Math.min(windowSize.width, 1200)- 48)*0.8, windowSize.width - innerPadding * 2);


  const descriptionBG = '#FAFCFF';

  const marketplaces: MarketplaceConfig[] = [
    {
      logo: 'https://raw.githubusercontent.com/reservoirprotocol/indexer/v5/src/models/sources/opensea-logo.svg',
      url: `https://opensea.io/assets/arbitrum/${CORE_CONTRACT_ADDRESS?.toLowerCase()}/${token.tokenId}`
    }
  ];

  document.title = `${token?.project?.name ?? 'Project'} #${token?.invocation ?? '0'}`;

  return token && (
    <Box sx={{marginTop: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: isMobile ? 0 : 4}} />
      <Stack spacing={2}>
        <TokenView
          tokenId={token.tokenId}
          width={width}
          aspectRatio={parseAspectRatio(token.project.scriptJSON)}
          live={true}
        />
        <Box height={10} />
        <Box width={width} sx={{boxShadow: '1px 2px 3px #606070', backgroundColor: descriptionBG, marginTop: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <Box sx={{ paddingLeft: `${innerPadding}px`, paddingRight: `${innerPadding}px` }} width={String(width - 2 * innerPadding) + "px"}>
            <Grid item md={1}>
              <Grid item md={4}>
                <Grid sx={{marginTop: 1, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                  <Typography fontWeight='bold' variant="h1" >
                    {token.project.name} #{token.invocation}
                  </Typography>
                  <Box sx={{display: "flex", justifyContent: "space-between"}}>
                    <Button
                      startIcon={<PlayArrow sx={{color: "#666"}}/>}
                      sx={{
                        fontSize: 14,
                        textTransform: "none",
                        minWidth: [0, 0, "64px"],
                        padding: [0, 0, "default"]
                      }}
                      onClick={() => {
                        window.open(`${GENERATOR_URL}/${CORE_CONTRACT_ADDRESS?.toLowerCase()}/${token.tokenId}`)
                      }}
                      >
                      <Typography fontSize="14px" display={["none", "none", "block"]}>
                        Live
                      </Typography>
                    </Button>
                    <Button
                      startIcon={<Pause sx={{color: "#666"}}/>}
                      sx={{
                        fontSize: 14,
                        textTransform: "none",
                        marginLeft: [1, 1, 2],
                        minWidth: [0, 0, "64px"],
                        padding: [0, 0, "default"]
                      }}
                      onClick={() => {
                        window.open(`${CCA_MEDIA_URL}/${token.tokenId}.png`)
                      }}
                      >
                      <Typography fontSize="14px" display={["none", "none", "block"]}>
                        Image
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
                <Typography fontStyle='oblique' variant="h6">
                  {token.project.artistName}
                </Typography>
              </Grid>
              <Box sx={{marginTop: 1, display: "flex", flexDirection: "row"}}>
                <Typography fontSize="16px" mb={0} mt={1}>
                  <b>Mint date: </b>{moment.unix(token.createdAt).format("LLL")}
                </Typography>
              </Box>
              <Box sx={{marginTop: 1, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Box sx={{marginTop: 1, display: "flex", flexDirection: "row"}}>
                  <Typography sx={{display: "flex", justifyContent: "space-between"}}>
                    <b>Owner:</b>&nbsp;<Address address={token.owner.id}></Address>
                  </Typography>
                </Box>
                {marketplaces?.length > 0 && marketplaces?.map((marketplace) => (
                  <Box key={marketplace.logo} sx={{marginTop: 1, marginLeft: 1, display: "flex", flexDirection: "row"}}>
                    <div>
                      <a target='_blank' rel='noreferrer' href={marketplace.url}>
                        <img
                          onClick={() => {
                            window.open(marketplace.url)
                          }}
                          style={{height:'25px',width:'25px'}}
                          src={marketplace.logo} />
                      </a>
                    </div>
                  </Box>
                  ))
                }
              </Box>
              <Divider />
              <Grid item md={6} mt={2}>
                <TokenTraits tokenId={token.tokenId}/>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Grid container spacing={2}>
          
        </Grid>
      </Stack>
    </Box>
  )
}

export default TokenDetails
