import { useParams } from "react-router-dom"
import Page from "components/Page"
import TokenDetails from "components/TokenDetails"
import { useState } from "react"

import { CORE_CONTRACT_ADDRESS } from "config"

const TokenPage = () => {
  const { id } = useParams()
  const [rendered, setRendered] = useState(false);
  const updateRendered = () => {
    setRendered(true);
  };
  return (
    <Page isRendered={rendered}> 
      {
        id && <TokenDetails id={`${CORE_CONTRACT_ADDRESS?.toLowerCase()}-${id}`} onRendered={updateRendered} /> 
      }
    </Page>
  )
}

export default TokenPage