
import { Box, Typography } from "@mui/material";
import Page from "components/Page"
import Projects from "components/Projects"
import useWindowSize from "hooks/useWindowSize";
import { useState } from "react";
import ReactGA from 'react-ga4';

let hasRunGA = false;

const AboutPage = () => {
  const [rendered, setRendered] = useState(true);
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < 1100;
  const updateRendered = () => {
    setRendered(true);
  };

  if (!hasRunGA) {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    hasRunGA = true;
  }
  const paragraphs = [
    `Concentric Art is an exploration in advancing the tooling used to create generative art. It is a fully on-chain platform on Arbitrum that supports C++, Python, Rust, and other languages using WebAssembly, in addition to JavaScript.`,
    `Code storage cost is an important part of launching WebAssembly-based projects, as in many cases all assets must be uploaded together. We seek to reduce the financial barriers for artists to publish their work and have chosen to use an L2 scaling solution. Our platform uses Arbitrum, an L2 built on Ethereum, due to its vibrant ecosystem, robust technical capabilities, and low storage and transaction costs.`,
    `Concentric Art was created out of a passion for collecting, displaying, and creating generative art. Our goal is to help further the possibilities of generative art by providing new tools to artists, and to provide a platform for STEM-themed art.`,
    `We are the first fully on-chain generative art platform to specifically support languages other than (and in addition to) JavaScript by leveraging the power of WebAssembly. Until now, generative art on the blockchain has largely been created using JavaScript. Originally created in 1995 for one of the earliest web browsers, JavaScript has since evolved to become the primary language driving modern websites today. WebAssembly was created in 2015 by major browser players such as Firefox, Chromium, and WebKit, and supports running bytecode-based languages traditionally used in desktop environments such as C++ and Rust natively in the browser. We focus on leveraging experimental languages to create cutting edge art and push the generative art space forward.`,
    `Our focus is to provide a curated platform for Science, Technology, Engineering, the Arts, and Math (STEAM) projects.`,
    `We would like to acknowledge the pioneering work and encouragement of Erick Calderon and Art Blocks in the creation of this platform.`
  ];
  
  let width = isMobile ? windowSize.width - 30 : windowSize.width - 260;

  document.title = 'About Us';
  const descriptionBG = '#FAFCFF';
  return (
    <Page isRendered={rendered}>
      <Box sx={{display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", minHeight: `${windowSize.height - 94 - 10}px`}}>
      <Box sx={{height: '50px'}} />
        <Box sx={{boxShadow: '1px 2px 3px #606070', backgroundColor: descriptionBG, marginTop: 1, width: `${width}px`}}>
        <Box sx={{marginLeft: '10px', marginTop: '10px', marginBottom: '15px'}}>
          <Typography variant="h5" fontSize={24} fontWeight='bold'>About Us</Typography>
        </Box>
          <Box sx={{marginLeft: '10px', marginRight: '10px'}}>
            {
              paragraphs && paragraphs.length > 0 && paragraphs.map((paragraph) =>
                <Typography sx={{marginBottom: 2, lineHeight: '24px'}} fontSize={18}>
                  {paragraph}
                </Typography>
              )
            }
          </Box>
        </Box>
        <Box sx={{height: '50px'}}/>
      </Box>
    </Page>
  )
}

export default AboutPage