import CssBaseline from "@mui/material/CssBaseline"
import "@rainbow-me/rainbowkit/styles.css"
import { ThemeProvider } from "@mui/material/styles"
import theme from "theme"
import { RainbowKitProvider, getDefaultWallets, lightTheme } from "@rainbow-me/rainbowkit"
import {chain, configureChains, createClient, WagmiConfig } from "wagmi"
import { infuraProvider } from "wagmi/providers/infura"
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from "wagmi/providers/public"
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client"
import { GRAPHQL_URL, ALCHEMY_KEY, INFURA_KEY, EXPECTED_CHAIN_ID } from "config"

const client = new ApolloClient({
  uri: GRAPHQL_URL,
  cache: new InMemoryCache()
})

// Defaults to arbitrum goerli testing network if mainnet is not set
const expectedChains = EXPECTED_CHAIN_ID === 42161 ? [chain.arbitrum] : EXPECTED_CHAIN_ID === 421613 ? [chain.arbitrumGoerli] : [chain.goerli]
const initialChain = EXPECTED_CHAIN_ID === 42161 ? chain.arbitrum : EXPECTED_CHAIN_ID === 421613 ? chain.arbitrumGoerli : chain.goerli

const { chains, provider, webSocketProvider } = configureChains(
  expectedChains,
  [
    alchemyProvider({apiKey: ALCHEMY_KEY, priority: 0, stallTimeout: 1_000}),
    infuraProvider({apiKey: INFURA_KEY, priority: 1, stallTimeout: 1_000}),
    publicProvider({priority: 2, stallTimeout: 1_000})
  ]
)

const { connectors } = getDefaultWallets({
  appName: "Engine", 
  chains
})

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider
})

interface Props {
  children: React.ReactNode
}

const AppProvider = ({children}:Props) => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
         chains={chains}
         initialChain={initialChain}
         theme={
          lightTheme({
            borderRadius: "medium",
            accentColor: '#edf1f5',
            accentColorForeground: '#000000',
            fontStack: 'rounded'
          })
        }>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </ApolloProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  )
}

export default AppProvider
