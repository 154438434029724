import Page from "components/Page"
import Projects from "components/Projects"
import { useState } from "react";
import ReactGA from 'react-ga4';

let hasRunGA = false;

const ProjectsPage = () => {
  const [rendered, setRendered] = useState(false);
  const updateRendered = () => {
    setRendered(true);
  };
  
  if (!hasRunGA) {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    hasRunGA = true;
  }

  document.title = 'Projects';
  return (
    <Page isRendered={rendered}>
      <Projects onRendered={updateRendered} />
    </Page>
  )
}

export default ProjectsPage