import { useState, useMemo } from "react"
import {
  Box,
  Typography,
  Alert,
  FormControl,
  InputLabel,
  NativeSelect,
  Pagination,
  Grid
} from "@mui/material"
import useTheme from "@mui/material/styles/useTheme"
import { PROJECTS_PER_PAGE } from "config"
import { OrderDirection, Project } from "utils/types"
import ProjectPreview from "components/ProjectPreview"
import Loading from "components/Loading"
import useProjects from "hooks/useProjects"
import useWindowSize from "hooks/useWindowSize"

const Projects = ({ onRendered }: { onRendered: any}) => {
  const theme = useTheme()
  const windowSize = useWindowSize()
  const [highestProjectId, setHighestProjectId] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const skip = currentPage * PROJECTS_PER_PAGE
  const first = PROJECTS_PER_PAGE
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.DESC)
  const { loading, error, data } = useProjects({skip, first, orderDirection})

  useMemo(() => {
    if (data?.projects?.length) {
      const projectIds = data.projects.map((project: Project) => Number(project.projectId))
      const maxProjectId = Math.max(...projectIds)
      if (maxProjectId > highestProjectId) {
        setHighestProjectId(maxProjectId)
      }
      onRendered();
    }
  }, [data, data?.projects, highestProjectId])

  let width = windowSize.width - 260
  const isMobile = windowSize.width < 1100;
  let tokenWidth = 280
  const maxColumns = 2
  // if (windowSize && !isNaN(windowSize.width)) {
  //   width = windowSize.width > theme.breakpoints.values.md
  //     ? (Math.min(windowSize.width, 1200)- 96)*1/maxColumns
  //       : windowSize.width > theme.breakpoints.values.sm
  //         ? windowSize.width - 64
  //         : windowSize.width - 48
  // }

  return (
    <Grid sx={{minHeight: `${windowSize.height - 94 - 10}px`}}>
      <Box sx={{minHeight: windowSize.height - 94 - 10}}>
        <Box sx={{display:"flex", justifyContent: "flex-end", alignItems: "flex-end"}}>
          <Box sx={{display: "flex", alignItems: "center", marginTop:"15px", marginRight: "0px"}}>
            <Box>
              {
                !error && !loading && data?.projects?.length > 0 &&
                (
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    <Typography fontWeight={600} align='right'>Sort</Typography>
                  </InputLabel>
                  <NativeSelect
                    value={orderDirection}
                    sx={{fontSize: 14}}
                    inputProps={{ IconComponent: () => null }}
                    onChange={(e) => {
                      setCurrentPage(0)
                      setOrderDirection(e.target.value as OrderDirection)
                    }}
                  >
                    <option value={OrderDirection.DESC}>Latest</option>
                    <option value={OrderDirection.ASC}>Earliest</option>
                  </NativeSelect>
                </FormControl>
                )
              }
            </Box>
          </Box>
        </Box>
        <Box sx={{marginTop: "-50px"}}>
          {
            loading ? 
            (
              <Box marginTop={10}>
                <Loading/>
              </Box>
            ) : 
            error ? 
            (
              <Box marginTop={10}>
                <Alert severity="error">
                  Error loading projects
                </Alert>
              </Box>
            ) : 
            data?.projects?.length > 0 ? 
            (
              <Grid sx={{margin: "64px 0"}}>
                {
                data?.projects && (
                  data.projects.map((project: Project) => (
                    <ProjectPreview
                      key={project.id}
                      project={project}
                      isMobile={isMobile}
                      width={width}
                      tokenWidth={tokenWidth}
                      showDescription
                    />
                  ))
                )
              }
              </Grid>
            ) : 
            data?.projects?.length === 0 ? (
              <Box marginTop={10}>
                <Alert severity="info">
                  No projects found
                </Alert>
              </Box>
            ) : 
            null
          }
          {
            !error && !loading && data?.projects?.length > 0 && Math.ceil(highestProjectId/PROJECTS_PER_PAGE) > 0 && (
              <Box sx={{display: "flex", justifyContent: "center", marginBottom: "50px"}}>
                <Pagination
                  count={Math.ceil(highestProjectId/PROJECTS_PER_PAGE)}
                  color="primary"
                  page={currentPage + 1}
                  onChange={(event, page) => {
                    window.scrollTo(0, 0)
                    setCurrentPage(page - 1)
                  }}/>
              </Box>
            )
          }
        </Box>
      </Box>
      <Grid item xs={true}/>
    </Grid>
  )
}

export default Projects