export const EXPECTED_CHAIN_ID = Number(process.env.REACT_APP_EXPECTED_CHAIN_ID)
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL
export const INFURA_KEY = process.env.REACT_APP_INFURA_KEY || ""
export const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_KEY || ""
export const CORE_CONTRACT_ADDRESS = process.env.REACT_APP_CORE_CONTRACT_ADDRESS
export const MINTER_FILTER_CONTRACT_ADDRESS = process.env.REACT_APP_MINTER_FILTER_CONTRACT_ADDRESS
export const MINT_CONTRACT_ADDRESS = process.env.REACT_APP_MINT_CONTRACT_ADDRESS
export const MINT_SET_PRICE_CONTRACT_ADDRESS = process.env.REACT_APP_MINT_SET_PRICE_CONTRACT_ADDRESS
export const TOKEN_URL = process.env.REACT_APP_TOKEN_URL
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL
export const CCA_MEDIA_URL = process.env.REACT_APP_CCA_MEDIA_URL
export const S3_MEDIA_URL = process.env.REACT_APP_S3_MEDIA_URL
export const GENERATOR_URL = process.env.REACT_APP_GENERATOR_URL
export const PROJECTS_PER_PAGE = 8
export const TOKENS_PER_PAGE = 10
export const MULTIPLY_GAS_LIMIT = 1.1
export const NOT_LIVE_YET = process.env.REACT_APP_NOT_LAUNCHED_YET ? true : false
