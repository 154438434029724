import { useQuery, gql } from "@apollo/client"
import { CORE_CONTRACT_ADDRESS, TOKENS_PER_PAGE } from "config"
import { OrderDirection } from "utils/types"

interface TokensQueryParams {
  first?: number
  skip?: number
  orderDirection?: OrderDirection
}

const tokensQuery = ({
  first,
  skip,
  orderDirection
}: TokensQueryParams) => `
  query GetTokens {
    tokens(
      first: ${first},
      skip: ${skip},
      orderBy: createdAt orderDirection: ${orderDirection},
      where: {
        contract: "${CORE_CONTRACT_ADDRESS?.toLowerCase()}"
      }
    ) {
      id
      tokenId
      invocation
    }
  }`

const useLatestNTokens = (n: number, params: TokensQueryParams) => {
  const orderDirection = params?.orderDirection || OrderDirection.DESC

  const { loading, error, data } = useQuery(gql(tokensQuery({
    first: n,
    skip: 0,
    orderDirection
  })))

  return {
    loading,
    error,
    data
  }
}

export default useLatestNTokens
