import { useState, useEffect } from "react"
import {
  Box,
  Typography,
  Paper,
  Grid
} from "@mui/material"
import useTheme from "@mui/material/styles/useTheme"
import { NOT_LIVE_YET, PROJECTS_PER_PAGE } from "config"
import { OrderDirection, Project } from "utils/types"
import useProjects from "hooks/useProjects"
import useWindowSize from "hooks/useWindowSize"
import LatestTokens from "./LatestTokens"

// import paperContainer from '../styles/Landing.css'

const LandingDetails = ({ onRendered, windowHeight }: { onRendered: any; windowHeight: number}) => {
  const theme = useTheme()
  const windowSize = useWindowSize()
  const [highestProjectId, setHighestProjectId] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const skip = currentPage * PROJECTS_PER_PAGE
  const first = PROJECTS_PER_PAGE
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.DESC)
  const { loading, error, data } = useProjects({skip, first, orderDirection})
  useEffect(() => {
    if (loading || error || data) {
      onRendered();
    }
  });

  let width = 280
  const maxColumns = 2
  // if (windowSize && !isNaN(windowSize.width)) {
  //   width = windowSize.width > theme.breakpoints.values.md
  //     ? (Math.min(windowSize.width, 1200)- 96)*1/maxColumns
  //       : windowSize.width > theme.breakpoints.values.sm
  //         ? windowSize.width - 64
  //         : windowSize.width - 48
  // }

  const fullBoxHeight = windowHeight;
  const fullBoxWidth = windowSize.width - 37;
  const landingText = 'The first fully on-chain generative art platform supporting JavaScript and non-JavaScript projects (C++, Python, Rust) using WebAssembly';

  return (
    <Box ml={0} pl={0} sx={{display: "flex", flexDirection: "column", alignItems: "center", width: fullBoxWidth}}>
      <Paper elevation={0} className={'paperContainer'} sx={{paddingTop: '10px', width: fullBoxWidth}}>
        <Grid container sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center"
          }}>
          <img style={{width: `${Math.min(fullBoxWidth, 700)}px`, marginTop: `20px`}} src={'/media/Concentric_Logo_Color.svg'} />
          <Box sx={{display:"flex", justifyContent: "center", alignItems: "center", marginLeft: '20px', marginRight: '20px', marginTop: `${fullBoxHeight / 16}px`}}> 
            <Typography variant="h3" align='center'>{landingText}</Typography>
          </Box>
          {
            NOT_LIVE_YET ? (
              <Box sx={{display:"flex", flexDirection: 'column', justifyContent: "flex-start", alignItems: "center", marginTop: `${fullBoxHeight / 16}px`, height: `${280 + fullBoxHeight / 8}px`}}>
              <Box sx={{height: `${fullBoxHeight / 16 + 50}px`, width: `${fullBoxWidth - 100}px`}}/>
                <Typography variant='h4' align='center' fontSize={44}>
                  Coming Soon
                </Typography>
              </Box>
              ) : (
              <Box>
                <Box sx={{display:"flex", justifyContent: "center", alignItems: "center", marginTop: `${fullBoxHeight / 16}px`}}>
                  <Typography variant='h4' align='center'>
                    Recent mints
                  </Typography>
                </Box>
                <Box sx={{display:"flex", justifyContent: "center", alignItems: "center", marginTop: `${fullBoxHeight / 16}px`, width: `${fullBoxWidth - 100}px` }}>
                  <LatestTokens />
                </Box>
              </Box>
            )
          }
          {/* <Box sx={{display:"flex", justifyContent: "center", alignItems: "center", marginTop: `${fullBoxHeight / 16}px`, width: `${fullBoxWidth - 100}px` }} /> */}
          {/* <Grid xs={12}/> */}
        </Grid>
      </Paper>
    </Box>
  )
}

export default LandingDetails