import {
  Box,
  Typography,
  Link,
  Grid
} from "@mui/material"
import { Project, Token } from "utils/types"
import { parseAspectRatio, parseDescription } from "utils/scriptJSON"
import TokenView from "components/TokenView"

interface Props {
  project: Project
  tokenWidth?: number
  width?: number
  isMobile?: boolean
  showDescription?: boolean
}

let selectedTokenId = -1;

const ProjectPreview = ({project, isMobile, width, tokenWidth=280, showDescription=false}: Props) => {
  if (!project) {
    return null
  }

  if (selectedTokenId < 0) {
    selectedTokenId = project?.tokens?.length ? Math.floor(project?.tokens?.length * Math.random()) : -1;
  }
  const token = (selectedTokenId >= 0 && project?.tokens[selectedTokenId]) as Token ?? {tokenId: 0, invocation: 0};
  // const token = (project?.tokens?.length && project?.tokens[Math.floor(project?.tokens?.length * Math.random())]) as Token ?? {tokenId: 0, invocation: 0};
  const descriptionBG = '#FAFCFF';
  const imgGap = 15;
  return (
    <Link href={`/project/${project.projectId}`} underline="hover" style={{textDecoration: 'none'}}>
      <Box sx={{boxShadow: '1px 2px 3px #606070', backgroundColor: descriptionBG, marginTop: 1, minHeight: `${tokenWidth + 40}px`}}>
        <Box sx={{marginLeft: `${imgGap}px`}}>
          <Grid sx={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'center' : 'center'}}>
            <Box sx={{display: 'flex', flexDirection: isMobile ? 'row' : 'column', alignItems: 'center', justifyContent: 'center', marginTop: `${imgGap}px`, marginRight: `${imgGap}px`, minWidth: `${tokenWidth}px`}}>
              <TokenView
                tokenId={token?.tokenId ?? 0}
                width={tokenWidth + 10}
                invocation={token?.invocation ?? 0}
                aspectRatio={parseAspectRatio(project.scriptJSON)}
                live={false}
                hideMintTag={true}
                isStatic={true}
                addGap={false}
              />
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: `${isMobile ? 10 : 0}px`}}>
              <Typography variant="h1" fontSize={36} align={isMobile ? 'center' : 'left'}>
                {project.name} by {project.artistName}
              </Typography>
              <Typography fontSize={14} fontStyle='italic' align={isMobile ? 'center' : 'left'}>
                {project.complete ? 'Complete' : project.paused ? 'Paused' : 'Open'}
              </Typography>
              {/* <Box sx={{display: "flex", alignItems:"center"}}>
                <ProjectStatusBadge complete={project.complete} paused={project.paused} startTime={project?.minterConfiguration?.startTime} />
                <ProjectDate
                  startTime={project.minterConfiguration?.startTime}
                />
              </Box> */}
              <Box marginTop={1} sx={{maxHeight: `${tokenWidth - 60}px`, overflow: 'auto'}}>
                <Typography noWrap sx={{whiteSpace: 'pre-wrap'}} align={isMobile ? 'center' : 'left'}>{parseDescription(project.description)}
                </Typography>
              </Box>
            </Box>
            <Grid xs={12} />
          </Grid>
        </Box>
      </Box>
    </Link>
  )
}

export default ProjectPreview