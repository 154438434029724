import useTheme from "@mui/material/styles/useTheme"
import { TOKENS_PER_PAGE } from "config"
import { OrderDirection, Token } from "utils/types"
import { 
  Grid,
  Link,
  Alert,
  Typography,
  Box
} from "@mui/material"
import Loading from "components/Loading"
import TokenView from "components/TokenView"
import useTokens from "hooks/useTokens"
import useWindowSize from "hooks/useWindowSize"
import BlankTokenView from './BlankTokenView';

interface Props {
  projectId: string
  first?: number
  skip?: number
  orderDirection?: OrderDirection
  aspectRatio?: number
  fillRow?: boolean
  isMobile?: boolean
  maxWidth?: number
}

const Tokens = ({
  projectId,
  first=TOKENS_PER_PAGE,
  skip=0,
  orderDirection=OrderDirection.ASC,
  aspectRatio=1,
  fillRow=false,
  isMobile=false,
  maxWidth=-1
}: Props) => {
  const theme = useTheme()
  const windowSize = useWindowSize()
  maxWidth = maxWidth < 0 ? windowSize.width : maxWidth;
  const {loading, error, data } = useTokens(projectId, {
    first,
    skip,
    orderDirection
  })

  if (loading) {
    return <Loading/>
  }

  if (error) {
    return (
      <Alert severity="error">
        Error loading tokens
      </Alert>
    )
  }

  if (!data || !data.tokens) {
    return (
      <Alert severity="info">
        No tokens found for this project.
      </Alert>
    )
  }

  let width = 280
  if (windowSize && !isNaN(windowSize.width)) {
    width = windowSize.width > theme.breakpoints.values.md
      ? (Math.min(windowSize.width, 1200)-96) / 3
      : (windowSize.width-60) / 2
  }
  width = Math.min(maxWidth, width);

  return (
    data.tokens.length > 0 ? 
      <Grid mr={0} ml={0} pr={0} pl={0} columns={3} sx={{justifyContent: 'center'}} container>
        {
          data.tokens.map(((token: Token, tokenInd: number) => (
            token ? (
            <Grid key={token.tokenId}>
              <Link href={`/token/${token.id.split('-')[1]}`}>
                <Box sx={{display: "flex", justifyContent: "center", flexDirection: 'column'}}>
                  <TokenView
                    tokenId={token.tokenId}
                    aspectRatio={aspectRatio}
                    width={width - 10}
                    isStatic={true}
                    addGap={true}
                  />
                  <Typography mt={0.25} fontWeight="bold" align='center'>
                    #{token.invocation.toString()}
                  </Typography>
                </Box>
              </Link>
            </Grid>
            ) : (
              <Grid key={tokenInd}>
                <BlankTokenView aspectRatio={aspectRatio} width={width} />
              </Grid>
            )
          )))
        }
      </Grid>
    : null
  )
}

export default Tokens