import { useEnsName } from "wagmi"
// import Tooltip from "@mui/material/Tooltip"
import 'react-tooltip/dist/react-tooltip.css'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Tooltip } from 'react-tooltip'
import { useState } from "react"

interface Props {
  address?: any
}

const Address = ({ address }: Props) => {
  const [copied, setCopied] = useState<boolean>(false)
  const ensName = useEnsName({
    address: address,
    chainId: 1
  })

  const shortAddress = address ? `${address.slice(0, 6)}...${ address.slice(38, 42)}` : null
  const resolvedAddress = ensName.data || address as string

  return (
    address !== null ?
    <div>
      <CopyToClipboard text={resolvedAddress} onCopy={() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 3000)
        }}>
        <div>
          <a style={{cursor: 'pointer'}} id='address-tooltip' data-tooltip-id='address-tooltip' data-tooltip-content={copied ? 'copied!' : resolvedAddress}>{ensName.data || shortAddress}</a>
          <Tooltip id='address-tooltip' clickable><span>copied!</span></Tooltip>
        </div>
      </CopyToClipboard>
    </div>
    : null
  )
}

export default Address