import { useQuery, gql } from "@apollo/client"
import { CORE_CONTRACT_ADDRESS } from "config"

const projectQuery = (id: string) => `
  query GetProject {
    project(
      id: "${id.toLowerCase()}",
      contract: "${CORE_CONTRACT_ADDRESS?.toLowerCase()}"
    ) {
      id
      projectId
      name
      description
      license
      locked
      pricePerTokenInWei
      active
      paused
      complete
      artistName
      artistAddress
      invocations
      maxInvocations
      scriptJSON
      currencyAddress
      currencySymbol
      createdAt
      activatedAt
      tokens (orderBy: createdAt orderDirection: desc) {
        id
        tokenId
        invocation
      }
      minterConfiguration {
        basePrice
        priceIsConfigured
        currencySymbol
        currencyAddress
      }
    }
  }`
/*
        startPrice
        startTime
        endTime
*/
const useProject = (id: string) => {
  const { loading, error, data } = useQuery(gql(projectQuery(id)))

  return {
    loading,
    error,
    data
  }
}

export default useProject
