import * as _ from 'lodash';
import useTheme from "@mui/material/styles/useTheme"
import { OrderDirection, Token } from "utils/types"
import { 
  Grid,
  Link,
  Alert,
  Box
} from "@mui/material"
import Loading from "components/Loading"
import TokenView from "components/TokenView"
import useLatestNTokens from "hooks/useLatestNTokens"
import useWindowSize from "hooks/useWindowSize"
import BlankTokenView from './BlankTokenView';

interface Props {
  first?: number
  skip?: number
  orderDirection?: OrderDirection
  aspectRatio?: number
  fillRow?: boolean
  isMobile?: boolean
  maxWidth?: number
}

const LatestTokens = ({
  aspectRatio=1,
  fillRow=false,
  isMobile=false,
  maxWidth=-1
}: Props) => {
  const windowSize = useWindowSize()
  maxWidth = maxWidth < 0 ? windowSize.width : maxWidth;
  const {loading, error, data } = useLatestNTokens(3, {})

  if (loading) {
    return <Loading/>
  }

  if (error) {
    return (
      <Alert severity="error">
        Error loading tokens
      </Alert>
    )
  }

  if (!data || !data.tokens) {
    return (<div />)
  }

  let width = 280
  // if (windowSize && !isNaN(windowSize.width)) {
  //   width = windowSize.width > theme.breakpoints.values.md
  //     ? (Math.min(windowSize.width, 1200)-96) / 3
  //     : (windowSize.width-60) / 2
  // }
  width = Math.min(maxWidth, width);

  const dataTokens = _.cloneDeep(data.tokens);
  const paddingBetween = 10;

  return (
    dataTokens.length > 0 ? 
      <Grid mr={0} ml={0} pr={0} pl={0} sx={{justifyContent: 'center'}} container>
        {
          dataTokens.map(((token: Token, tokenInd: number) => (
            token ? (
            <Grid key={token.tokenId}>
              <Link href={`/token/${token.id.split('-')[1]}`}>
                <Box sx={{display: "flex", justifyContent: "center", flexDirection: 'column', margin: `${paddingBetween}px`}}>
                  <TokenView
                    tokenId={token.tokenId}
                    aspectRatio={aspectRatio}
                    width={width}
                    isStatic={true}
                  />
                </Box>
              </Link>
            </Grid>
            ) : (
              <Grid key={tokenInd}>
                <BlankTokenView aspectRatio={aspectRatio} width={width} />
              </Grid>
            )
          )))
        }
      </Grid>
    : null
  )
}

export default LatestTokens