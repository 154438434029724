import useTheme from "@mui/material/styles/useTheme"
import { useEffect, useState } from "react"
import {
  Box,
  Grid,
  Divider,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  NativeSelect,
  Pagination,
  Alert
} from "@mui/material"
import { TbExternalLink } from 'react-icons/tb'
import { TOKENS_PER_PAGE } from "config"
import { OrderDirection } from "utils/types"
import { parseScriptType, parseAspectRatio, parseDescription, parseLanguage, parseCompilationNotes } from "utils/scriptJSON"
import ProjectDate from "components/ProjectDate"
import TokenView from "components/TokenView"
import Tokens from "components/Tokens"
import MintingInterface from "components/MintingInterface"
import Loading from "components/Loading"
import useProject from "hooks/useProject"
import useWindowSize from "hooks/useWindowSize"
import ReactGA from 'react-ga4';

interface Props {
  id: string,
  onRendered: any
}

let hasRunGA = false;
let selectedTokenId = -1;

const ProjectDetails = ({ id, onRendered }: Props) => {
  const theme = useTheme()
  const windowSize = useWindowSize()
  const { loading, error, data } = useProject(id)
  const [currentPage, setCurrentPage] = useState(0)
  const [orderDirection, setOrderDirection] = useState(OrderDirection.DESC)
  const project = structuredClone(data?.project) // TODO_REMOVE

  if (!hasRunGA) {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    hasRunGA = true;
  }

  if (selectedTokenId < 0) {
    selectedTokenId = project?.tokens?.length ? Math.floor(project?.tokens?.length * Math.random()) : -1;
  }
  const token = selectedTokenId >= 0 && project?.tokens[selectedTokenId]
  const width = windowSize.width > theme.breakpoints.values.md
    ? (Math.min(windowSize.width, 1200)-48)*0.8
      : windowSize.width > theme.breakpoints.values.sm
        ? windowSize.width - 48
        : windowSize.width - 32

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        onRendered();
      }, 750);
    }
  });
  if (error) {
    return (
      <Box>
        <Alert severity="error">
          Error loading project
        </Alert>
      </Box>
    )
  }

  if (loading) {
    return <Loading/>
  }

  const isJS = parseLanguage(project.description) === 'JavaScript';

  const descriptionBG = '#FAFCFF';

  const isMobile = windowSize.width < 1100;
  const leftBorder = isMobile ? 20 : 50;
  const rightBorder = isMobile ? 20 : 40;
  const middleGap = isMobile ? 0 : 20;
  const innerPadding = 10;
  const descriptionMinWidth = isMobile ? windowSize.width - (leftBorder + rightBorder) : 500;
  const descriptionWidth = Math.max((windowSize.width - (leftBorder + rightBorder + middleGap)) / 2 - 21, descriptionMinWidth);
  const tokenWidth = Math.max((windowSize.width - (leftBorder + rightBorder + middleGap)) / 2  - 21, descriptionMinWidth + 21);
  const descriptionAndTokenWidth = isMobile ? descriptionWidth : descriptionWidth + tokenWidth + middleGap;
  const descriptionAndTokenWidthAndBorder = descriptionAndTokenWidth + leftBorder + rightBorder;
  // TODO_REMOVE
  // project.projectId = '1'
  // project.artistAddress = '0x2b1a1da8167b43bfecd63bc3e4f22ad032f53b76'
  // project.description = 'heigh ho, heigh ho, its off to work we go in a one horse open sleigh with all the cowbells in the world and charlie brown kicking his 4000th football with lucy\n\n a \t \t b \t\t c\n\nPrimary Language: C++\nCompilation Notes: Emscription 3.13.3. Utilized blah blah lorem ipsum \n\nTried to justify this but it kept going and going until the bee movie started and then it was all ogre from there thanks shrek for the braindump that this was an exhausting test that is not the only means of discovering the truth'
  // project.scriptJSON = '{"aspectRatio":"1.0","language":"C++","compilationNotes":""}'
  // project.license = 'NFT License 2.0'

  document.title = project.name;

  return project && (
    <Box sx={{marginTop: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      <Grid width={descriptionAndTokenWidthAndBorder} spacing={2} container sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: isMobile ? "column-reverse" : "row",
            alignItems: isMobile ? "center" : "normal"
          }} id="project-description">
        <Box width={leftBorder} />
        <Box width={descriptionWidth} sx={{marginLeft:`${isMobile ? leftBorder / 2 : 5}px`}}>
          <ProjectDate startTime={project?.minterConfiguration?.startTime}/>
          <Grid container sx={{boxShadow: '1px 2px 3px #606070', backgroundColor: descriptionBG, marginTop: 1}} minHeight={isMobile ? 0 : Math.max(tokenWidth - 21, 0)} alignItems='stretch' direction='column'>
            <Box sx={{ paddingLeft: `${innerPadding}px`, paddingRight: `${innerPadding}px` }} maxWidth={String(descriptionAndTokenWidthAndBorder - 2 * innerPadding) + "px"}>
              <Box sx={{width: "100%", paddingLeft: [0, 0, 0]}}>
                <Box sx={{display: "flex", flexDirection: "row"}}>
                  <Typography fontWeight='bold' variant="h1" mt={1}>
                    {project.name} 
                  </Typography>
                  {
                    project.website && (
                      <Box mt={4.4} ml={1.5}>
                        <a target='_blank' rel='noreferrer' href={project.website}>
                          <TbExternalLink size={27} />
                        </a>
                      </Box>
                    )
                  }
                </Box>
                
                <Typography fontStyle='oblique' variant="h6" mb={2}>
                  {project.artistName}
                </Typography>
                <Divider sx={{width: '100%'}} />
                <MintingInterface
                  projectId={project.projectId} 
                  artistAddress={project.artistAddress}
                  scriptAspectRatio={parseAspectRatio(project.scriptJSON)}
                />
              </Box>
            </Box>
            <Box sx={{ paddingLeft: `${innerPadding}px`, paddingRight: `${innerPadding}px` }} maxWidth={String(descriptionAndTokenWidthAndBorder - 2 * innerPadding) + "px"}>
              <Box mb={2}>
                <Typography sx={{whiteSpace: 'pre-wrap'}}>
                  {parseDescription(project.description)}
                </Typography>
              </Box>
            </Box>
            {/* <Box sx={{ paddingLeft: `${innerPadding}px`, paddingRight: `${innerPadding}px` }} width={String(descriptionAndTokenWidthAndBorder - 2 * innerPadding) + "px"}>
              <Divider sx={{width: '100%'}} />
            </Box> */}
            {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'space-between', height: '200px' }} width={1} /> */}
            <Grid item xs={true}/>
            <Box sx={{ paddingLeft: `${innerPadding}px`, paddingRight: `${innerPadding}px` }} maxWidth={String(descriptionAndTokenWidthAndBorder - 2 * innerPadding) + "px"}>
              {descriptionWidth - 2 * innerPadding < 450 ? (<Box sx={{display: "flex", marginTop: 4, flexDirection: "column", borderTop: 1, borderBottom: 1, borderColor:'#0000001f'}}>
                <Box minWidth={descriptionWidth - 20} sx={{borderBottom: 1, borderColor:'#0000001f'}}>
                  <Typography>
                    <strong>License</strong>
                  </Typography>
                  <Typography>
                    {project.license}
                  </Typography>
                </Box>
                <Box minWidth={String(descriptionWidth - 2 * innerPadding) + "px"} sx={{borderBottom: 1, borderColor:'#0000001f'}}>
                  <Typography>
                    <strong>Primary Language</strong>
                  </Typography>
                  <Typography>
                    {parseLanguage(project.description)}
                  </Typography>
                </Box>
                {isJS ? 
                  (<Box ml={0} minWidth={String(descriptionWidth - 2 * innerPadding) + "px"}>
                    <Typography>
                      <strong>Library</strong>
                    </Typography>
                    <Typography>
                      {parseScriptType(project.scriptJSON)}
                    </Typography>
                  </Box>)
                  :
                  (<Box ml={0} minWidth={String(descriptionWidth - 2 * innerPadding) + "px"}>
                    <Typography>
                      <strong>Compilation Notes</strong>
                    </Typography>
                    <Typography sx={{whiteSpace: 'pre-wrap'}}>
                      {parseCompilationNotes(project.description)}
                    </Typography>
                  </Box>)
                }
              </Box>) : (
              <Box sx={{display: "flex", marginTop: 4, flexDirection: "row", borderTop: 1, borderBottom: 1, borderColor:'#0000001f'}}>
                <Box minWidth={128} sx={{borderRight: 1, borderColor:'#0000001f'}}>
                  <Typography>
                    <strong>License</strong>
                  </Typography>
                  <Typography>
                    {project.license}
                  </Typography>
                </Box>
                <Box ml={2} minWidth={147} mr={2} sx={{borderRight: 1, borderColor:'#0000001f'}}>
                  <Typography>
                    <strong>Primary Language</strong>
                  </Typography>
                  <Typography>
                    {parseLanguage(project.description)}
                  </Typography>
                </Box>
                {isJS ? 
                  (<Box ml={0} pr={2} sx={{borderRight: 1, borderColor:'#0000001f'}}>
                    <Typography>
                      <strong>Library</strong>
                    </Typography>
                    <Typography>
                      {parseScriptType(project.scriptJSON)}
                    </Typography>
                  </Box>)
                  :
                  (<Box ml={0} pr={2} sx={{borderRight: 1, borderColor:'#0000001f'}}>
                    <Typography>
                      <strong>Compilation Notes</strong>
                    </Typography>
                    <Typography sx={{whiteSpace: 'pre-wrap'}}>
                      {parseCompilationNotes(project.description)}
                    </Typography>
                  </Box>)
                }
              </Box>
              )}
            </Box>
            <Box mt={4}></Box>
          </Grid>
        </Box>
        <Box width={middleGap} id={"middle-gap"}/>
        {
          token && (
            <Grid item width={tokenWidth} mr={1} sx={{marginRight: "0px"}} id="fooo">
              <TokenView
                tokenId={token.tokenId}
                width={tokenWidth}
                invocation={token.invocation}
                aspectRatio={parseAspectRatio(project.scriptJSON)}
                live={true}
                addGap={false}
                fromProjectDetail={true}
              />
            </Grid>
          )
        }
        <Box width={rightBorder} />
      </Grid>
      <Divider sx={{width: `${descriptionAndTokenWidth - 12}px`, marginTop: "21px"}} />
      { project.invocations > 0 && (
        <Box width={descriptionAndTokenWidth - 12}>
          <Grid container px={1} pb={3} display="flex" mt={2} sx={{justifyContent: "space-between"}}>
            <Typography align="left" variant="h4" ml={-1.2}>
              Gallery ({project.invocations} token{Number(project.invocations) === 1 ? "" : "s"})
            </Typography>
            <Box sx={{align:"right"}}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  <Typography fontWeight={600} align='right'>Sort</Typography>
                </InputLabel>
                <NativeSelect
                  value={orderDirection}
                  sx={{fontSize: 14}}
                  inputProps={{ IconComponent: () => null }}
                  onChange={(e) => {
                    setOrderDirection(e.target.value as OrderDirection)
                  }}
                >
                  <option value={OrderDirection.DESC}>Mint order high to low</option>
                  <option value={OrderDirection.ASC}>Mint order low to high</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>
          <Box pt={3} pl={0} pr={0} mt={0} mb={0} display="flex" flexDirection="column" sx={{boxShadow: '1px 2px 5px #606070'}}>
            <Tokens
              projectId={id}
              first={TOKENS_PER_PAGE}
              skip={currentPage*TOKENS_PER_PAGE}
              orderDirection={orderDirection}
              aspectRatio={parseAspectRatio(project.scriptJSON)}
              fillRow={true}
              isMobile={isMobile}
              maxWidth={descriptionAndTokenWidth}
            />
            <Box sx={{display: "flex", justifyContent: "center"}}>
              <Stack mt={3} mb={3} spacing={2}>
                <Pagination
                  count={Math.ceil(project.invocations/TOKENS_PER_PAGE)}
                  color="primary"
                  page={currentPage + 1}
                  onChange={(event, page) => {
                    setCurrentPage(page - 1)
                  }}
                />
              </Stack>
            </Box>
          </Box>
          <Box mb={3}>

          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ProjectDetails
