import { CORE_CONTRACT_ADDRESS } from "config"
import {
  Box,
  Card,
  Link
} from "@mui/material"
import TokenImage from "components/TokenImage"
import TokenLive from "components/TokenLive"

interface Props {
  tokenId: string
  width: number
  invocation?: BigInt
  aspectRatio?: number
  live?: boolean
  hideMintTag?: boolean
  isStatic?: boolean
  addGap?: boolean
  fromProjectDetail?: boolean
}

let marginGap = 0;

const TokenView = ({
  tokenId,
  width,
  invocation,
  aspectRatio=1,
  live=false,
  hideMintTag=false,
  isStatic=false,
  addGap=false,
  fromProjectDetail=false
}: Props) => {
  const height = width / aspectRatio;
  if (addGap && !live) {
    marginGap = 10;
  }
  return (
    <Box width={String(width)+"px"} height={String(height)+"px"} sx={{display: "flex", justifyContent: `${addGap ? "center" : "flex-start"}`, alignItems: `${addGap ? "center" : "flex-start"}`, flexDirection: "column"}}>
      { invocation !== undefined && (hideMintTag ? (
        <Box sx={{width: "100%", height: '21px'}} />
      ) :
        (
        <Box sx={{width: "100%", display: "flex", justifyContent: "flex-end", paddingRight: `${addGap ? "10" : "0"}px`}}>
          <Link href={`/token/${CORE_CONTRACT_ADDRESS}-${tokenId}`} sx={{fontSize: "14px", textDecoration: "none"}}>
            Mint #{ invocation?.toString() }
          </Link>
          <Box width="21px"/>
        </Box>
        )
      )
      }
      {
        live ? 
        (
          <Box width={String(width - (fromProjectDetail ? 21 : 0))+"px"} height={String(height - (fromProjectDetail ? 21 : 0))+"px"} sx={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center"}}>
            <Card sx={{borderRadius: 1, boxShadow: 0, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end"}}>
              <TokenLive tokenId={tokenId} width={width - (fromProjectDetail ? 21 : 0)} height={height - (fromProjectDetail ? 21 : 0)}/>
            </Card>
          </Box>
        ) :
        (
          <Box width={String((width - 2 * marginGap))+"px"} height={String((height - 2 * marginGap))+"px"} sx={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center"}}>
            <Card sx={{borderRadius: 1, boxShadow: 0, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <TokenImage tokenId={tokenId} width={(width - 2*marginGap)} height={(height - 2*marginGap)} isStatic={isStatic}/>
            </Card>
          </Box>
        )
      }
    </Box>
  )
}

export default TokenView