import { 
  Box, 
  Container, 
  Grid, 
  Typography 
} from "@mui/material"
import ReactGA from 'react-ga4';
import Footer from "components/Footer"
import Header from "components/Header"
import LandingDetails from "components/LandingDetails"
import useWindowSize from "hooks/useWindowSize"
import { useState } from "react"

let hasRunGA = false;

const LandingPage = () => {
  const [rendered, setRendered] = useState(false);
  const updateRendered = () => {
    setRendered(true);
  };

  if (!hasRunGA) {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    hasRunGA = true;
  }

  const windowSize = useWindowSize();
  const footerHeight = 25;

  return (
    <Grid sx={{display: "flex", flexDirection: "column", alignContent: "center", height: windowSize.height - 1}} alignItems='stretch' direction='column'>
      <Box ml={0} pl={0} sx={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
        <Header/>
        <main>
          <Grid xs={12}>
            <Container sx={{paddingTop: 1, marginLeft: '0 !important', marginRight: '0 !important', paddingLeft: '0 !important', paddingRight: '0 !important'}}>
              <LandingDetails onRendered={updateRendered} windowHeight={windowSize.height - footerHeight} />
            </Container>
          </Grid>
        </main>
      </Box>
      <Grid item xs={true}/>
      <Box>
        {rendered && <Footer/>}
      </Box>
    </Grid>
  )
}

export default LandingPage