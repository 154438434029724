import { 
  Container, 
  Box,
  Grid
} from "@mui/material"

import Header from "components/Header"
import { stringify } from "querystring"
import { Children, cloneElement, JSXElementConstructor, ReactElement, useEffect, useState } from "react"
import Footer from "./Footer"

interface Props {
  children: React.ReactNode,
  isRendered: any
}

const Page = ({ children, isRendered }: Props) => {
  const [rendered, setRendered] = useState(isRendered);

  useEffect(() => {
    setRendered(isRendered);
  });

  const updateRendered = () => {
    setRendered(true);
  };

  children = Children.map(children, el => {
    return cloneElement(el as ReactElement<any, string | JSXElementConstructor<any>>, { setRendered: updateRendered });
  });

  return (
    <Grid sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center"}} alignItems='stretch' direction='column'>
      <Header/>
      <main>
        <Grid xs={12}>
          <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center"}}>
            <Container sx={{paddingTop: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center"}}>
              {children}
            </Container>
          </Box>
        </Grid>
      </main>
      <Grid item xs={true}/>
      {rendered && <Footer/>}
    </Grid>
  )
}

export default Page
