import { useParams } from "react-router-dom"
import { CORE_CONTRACT_ADDRESS } from "config"
import Page from "components/Page"
import ProjectDetails from "components/ProjectDetails"
import { useState } from "react"

const ProjectPage = () => {
  const { projectId } = useParams()
  const [rendered, setRendered] = useState(false);
  const updateRendered = () => {
    setRendered(true);
  };

  return (
    <Page isRendered={rendered}> 
      {
        projectId && <ProjectDetails id={CORE_CONTRACT_ADDRESS?.toLowerCase()+"-"+projectId} onRendered={updateRendered} />
      }
    </Page>
  )
}

export default ProjectPage