export const parseJson = (json: string) => {
  try {
    return JSON.parse(json)
  } catch (error) {
    return null
  }
}

export const parseAspectRatio = (scriptJSON: string) => {
  const scriptParams = parseJson(scriptJSON)

  if (!scriptParams) {
    return 1
  }

  const { aspectRatio } = scriptParams

  if (typeof aspectRatio === "string") {
    if (aspectRatio.indexOf("/") !== -1) {
      const [numerator, denominator] = aspectRatio.split("/")
      return parseFloat(numerator) / parseFloat(denominator)
    } else {
      return parseFloat(aspectRatio)
    }
  }
  return aspectRatio
}

export const parseScriptType = (scriptJSON: string) => {
  const scriptParams = parseJson(scriptJSON)
  return scriptParams?.type
}

export const parseLanguage = (description: string) => {
  const descriptionLines = description?.split(/\r?\n/) ?? [];
  const language = descriptionLines.find((descriptionLine) => descriptionLine.substring(0, 18) === 'Primary Language: ')?.substring(18);
  if (language && language.length > 0) {
    return language.trim();
  } else {
    return undefined;
  }
}

export const parseDescription = (description: string) => {
  if (description && description.indexOf('Primary Language: ') > -1) {
    return description.substring(0, description.indexOf('Primary Language: '));
  }
  return description?.trim();
}

export const parseCompilationNotes = (description: string) => {
  if (description && description.indexOf('Primary Language: ') > -1) {
    const restOfDescription = description?.substring(description.lastIndexOf('Primary Language: '));
    if (restOfDescription.indexOf('Compilation Notes: ') > -1) {
      return restOfDescription.substring(restOfDescription.indexOf('Compilation Notes: ') + 19).trim();
    }
  }
  return undefined;
}