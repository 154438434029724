import { useState } from "react"
import { 
  Box,
  Link,
  Typography
} from "@mui/material"
import useWindowSize from "hooks/useWindowSize"

const Footer = () => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const windowSize = useWindowSize()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const isMobile = windowSize.width < 350;

  return (
    <Box sx={{width: "100%", display: "flex", justifyContent: "center", flexDirection: isMobile ? 'column' : 'row', height: '30px'}}>
      {/* <Toolbar sx={{width: "100%", display: "flex", margin: "auto", justifyContent: "space-between", backgroundColor: "white"}}> */}
        <Box sx={{display: "flex", backgroundColor: "white", flexDirection: isMobile ? 'column' : 'row', justifyContent: "center", alignItems: "center"}}>
          <Box sx={{paddingRight: "25px"}}>
            <Link href="/" sx={{display: "flex"}}>
              <img src="/media/ArtBlocksEngine_Logo.svg" alt="Art Blocks Engine" height={26}></img>
            </Link>
          </Box>
          <Typography className='footer-text'>&#169; 2023 Variadic Labs LLC. </Typography>
          <Box sx={{display: "flex", alignItems: "center", marginTop: "5px", marginBottom: "5px"}}>
            <Link href='/terms-of-service'>
              <Typography className='footer-text' sx={{marginLeft: 0.3}}>Terms</Typography>
            </Link>
            <Typography className='footer-text' sx={{marginLeft: 0.3, marginRight: 0.3}}> and </Typography>
            <Link href='/privacy-policy'>
              <Typography className='footer-text'>Privacy</Typography>
            </Link>
          </Box>
        </Box>
    </Box>
  )
}

export default Footer